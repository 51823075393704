import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Observable } from "rxjs";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_api_keys {
  constructor(private ims: Imports, private dealer_plan: dealer_plan, private permission: Permission) {}

  public apiKeys$w(): Observable<any> {
    return this.ims.thirdPartyService.apiKeys$w;
  }
  public apiKey$w(): Observable<any> {
    return this.ims.thirdPartyService.apiKey$w;
  }

  public async get_apiKeys(): Promise<Array<any>> | undefined {
    await this.load_apiKeys();
    return this.ims.thirdPartyService.apiKeys$s.getValue();
  }
  public async get_apiKey(apiKeyId): Promise<Array<any>> | undefined {
    await this.load_apiKey(apiKeyId);
    return this.ims.thirdPartyService.apiKey$s.getValue();
  }

  public async load_apiKeys(): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasApiKeysReadPermission = this.permission.has("dealer_apikey_read")
      if(!hasApiKeysReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.thirdPartyService.getAPIKeys(dealer_id).toPromise();
      return res[0];
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get API Key List` });
    }
  }

  public async load_apiKey(apiKeyId): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasApiKeysReadPermission = this.permission.has("dealer_apikey_read")
      if(!hasApiKeysReadPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.thirdPartyService.getAPIKey(dealer_id, apiKeyId).toPromise();
      return res[0];
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Get API Key List` });
    }
  }

  public async create_api_keys(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.thirdPartyService.createAPIKey(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create API Key (${data.name})` });
      return false;
    }
  }

  public async update_api_keys(apiKeyId: number, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.thirdPartyService.updateAPIKey(dealer_id, apiKeyId, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Update API Key(Id: ${apiKeyId})` });
      return false;
    }
  }

  public async delete_api_keys(apiKeyId: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.thirdPartyService.deleteAPIKey(dealer_id, apiKeyId).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete API Key(Id: ${apiKeyId})` });
      return false;
    }
  }

  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}

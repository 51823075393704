import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class dealer_division {
  constructor(private ims: Imports, private dealer_plan: dealer_plan, private permission: Permission) {}

  public divisions$w(): Observable<any> {
    return this.ims.divisionService.divisions$w;
  }
  public my_divisions$w(): Observable<any> {
    return this.ims.divisionService.my_divisions$w;
  }
  public divisions_members$w(): Observable<any> {
    return this.ims.divisionService.divisions_members$w;
  }

  public async get_divisions(): Promise<Array<any>> | undefined {
    if(!this.ims.divisionService.divisions$s.getValue()?.length) await this.load_divisions();
    return this.ims.divisionService.divisions$s.getValue();
  }

  public async get_my_divisions(): Promise<Array<any>> | undefined {
    if(!this.ims.divisionService.my_divisions$s.getValue()?.length) await this.load_my_divisions();
    return this.ims.divisionService.my_divisions$s.getValue();
  }

  public async get_division_members(division_id: number): Promise<Array<any>> | undefined {
    await this.load_division_members(division_id);
    return this.ims.divisionService.my_divisions$s.getValue();
  }

  public async load_divisions(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadDivisionPermission = this.permission.has("dealer_division_read")
      if(!hasReadDivisionPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.getAllDivisions(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Dealer Divisions" });
      return false;
    }
  }

  public async load_my_divisions(): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadDivisionPermission = this.permission.has("dealer_division_read")
      if(!hasReadDivisionPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.getMyDivisions(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get my Divisions" });
      return false;
    }
  }

  public async load_division_members(division_id): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadDivisionMemberPermission = this.permission.has("dealer_division_member_read")
      if(!hasReadDivisionMemberPermission) return
      
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.getAllMemberForDivision(dealer_id, division_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Division Members" });
      return false;
    }
  }

  public async create_division(data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.createDivision(dealer_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Create Division" });
      return false;
    }
  }

  public async update_division(division_id: number, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.updateDivision(dealer_id, division_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async delete_all_division(): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.deleteAllDivisions(dealer_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async delete_division(division_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.deleteDivision(dealer_id, division_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async add_users_to_division(division_id: number, data: any[]): Promise<boolean> {
    try {
      if (data?.length > 100) throw new Error("too many data");

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.createMemberForDivision(dealer_id, division_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async add_sites_to_division(division_id: number, data: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");
      if (data?.length > 100) throw new Error("too many data");

      await this.ims.divisionService.addSitesForDivision(dealer_id, division_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async delete_site_to_division(site_id: any): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.deleteSiteForDivision(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  public async delete_user_to_division(division_id: number, user_id: number): Promise<boolean> {
    try {
      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.divisionService.deleteMemberForDivision(dealer_id, division_id, user_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Update Division" });
      return false;
    }
  }

  // --------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}

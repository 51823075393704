
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Helper } from 'src/4services/2helper';
import { SitesService } from './sites.service';
import { UsersService } from './users.service';
import { RolesService } from './roles.service';
import { EventsService } from './events.service';
import { DomSanitizer } from '@angular/platform-browser';

import moment from 'moment-timezone';

interface eventIdListEl {
  eventId: string, 
  videoLogId: string, 

  eventTime: string, // 2021-01-07T00:12:22
  storageTime: string, // 2021-01-07T00:12:22

  lastEventType: string,
  videoAILogId: string, 
  videoAIResult: string, 
  statusText: string,
  email: string,
  ip: string,
  userId: number,
  eventCode: any[],
  isSendXmlSignal: number,

  meta: null, // 이것도 video ai결과 표시위해 필요함.
  isSnapshotUpload: boolean,
  isVideoUpload: boolean,
  isHasVerification: boolean,
  isAutomation: boolean,
  isDisarmedEvent: boolean,
  isTimelapse: boolean,
  isPlayback: boolean,
  log: any
}
interface audioLogIdListEl {
  lastLogId: string, 
  deviceId: string,
  isAutomation: boolean,
  eventCode: any[],
  isSendXmlSignal: number,
  log: any,
  logList: any[]
}
interface relayLogIdListEl {
  lastLogId: string, 
  deviceId: string,
  momentaryDelay: number,
  momentaryEngagedIcon: any,
  eventCode: any[],
  isSendXmlSignal: number,
  log: any,
  logList: any[]
}

@Injectable()
export class EventViewerService {
  // This service is only for parsing in event viewer
  // so, I didn't inject app instance
  // it is used in Event viewer, Event viewer log card components

  constructor(
    private helper: Helper,
    private sitesService: SitesService,
    private eventsService: EventsService,
    private usersService: UsersService,
    private rolesService: RolesService,
    private sanitizer: DomSanitizer,
  ){}

  // 선언. 이 변수는 service 내에서만 사용(캡슐화)
  public isEnoughInit$s = new BehaviorSubject<boolean>(false);
  public filteredLogs$s = new BehaviorSubject<any[]>([]);
  public sortOrder$s = new BehaviorSubject<string>('Ascending'); 
  public sortOrderOptions: string[] = [ 'Ascending', 'Descending' ]
  public selectedLogs$s = new BehaviorSubject<any[]>([]);
  public isClearSelectedLogs$s = new BehaviorSubject<boolean>(false);

  public eventCategory$s = new BehaviorSubject<any[]>([
    { name : 'Armed / Disarmed', label: 'arming', checked: true, value: null, isArmedCategory: true, disabled: false },
    { name : 'Alarm Video', label: 'alarm-video', checked: true, value: [], isArmedCategory: false, disabled: false },
    { name : 'Playback Video', label: 'playback', checked: false, value: [], isArmedCategory: false, disabled: false},
    { name : 'Automation Action', label: 'automation', checked: false, value: [], isArmedCategory: false, disabled: true },
    { name : 'Manual Relay Event', label: 'relay', checked: false, value: null, isArmedCategory: false, disabled: false },
    { name : 'Manual Audio Broadcast', label: 'audio', checked: false, value: null, isArmedCategory: false, disabled: false },
    { name : 'Time-lapse Event', label: 'timelapse', checked: false, value: null, isArmedCategory: false, disabled: false },
    { name : 'Disarmed Event', label: 'disarmed-event', checked: false, value: [], isArmedCategory: false, disabled: false , isBeta: false,},
  ])
  public eventFilterCategory$s = new BehaviorSubject<any[]>([
    { name : 'True', label: 'video-ai', checked: true, value: null, disabled: false },
    { name : 'False', label: 'video-ai', checked: false, value: null, disabled: false },
    { name : 'Not Analyzed', label: 'video-ai', checked: true, value: null, disabled: false},
    { name : 'Failed', label: 'video-ai', checked: true, value: null, disabled: false},
    { name : 'Verification Request', label: 'verification', checked: false, value: null, disabled: false},
    { name : 'Operator Viewed', label: 'operator-viewed', checked: false, value: null, disabled: false},
  ])
  public otherCategory$s = new BehaviorSubject<any[]>([
    // { name : 'Trouble', label: 'trouble', checked: false, value: null, isArmedCategory: false},
    { name : 'Others', label: 'default', checked: false, value: [], disabled: false, isUnfold: false, selectCount: 0, subCategory: []},
  ])
  public otherSubCategory$s = new BehaviorSubject<any[]>([
    // { name : 'Others', label: 'default', checked: false, value: null },
  ])
  public selCategoryList$s = new BehaviorSubject<any[]>([])
  public snapshotPermission$s = new BehaviorSubject<boolean>(false)
  public isReadyFilteredLogs$s = new BehaviorSubject<boolean>(false)

  public isBuildReportMode$s = new BehaviorSubject<boolean>(false)
  public isOpenDetailModal$s = new BehaviorSubject<any>({status: false, log: null})

  private startDate: string = ''
  public lastLogIdInOriginLogList$s = new BehaviorSubject<string>('')

  public xmlEventCodeList$s = new BehaviorSubject<any[]>([])
  private operatorMemberIds = []

  public selectedDate$s = new BehaviorSubject<string>('Today')
  public todayInTimezone = moment().tz(this.sitesService.selSite.timezone)
  public today = this.todayInTimezone.clone().format('MMM / DD / YYYY');
  public eventListByDate = {} // for event counts

  public isShowErrInTextArea = false

  // 외부에서 $s들을 접근하고 변경하게 하는 변수들. 컴포넌트에서 실제로 사용하기
  isEnoughInit$w = this.isEnoughInit$s.asObservable();
  filteredLogs$w = this.filteredLogs$s.asObservable();
  sortOrder$w = this.sortOrder$s.asObservable();
  selectedLogs$w = this.selectedLogs$s.asObservable();

  eventCategory$w = this.eventCategory$s.asObservable();
  eventFilterCategory$w = this.eventFilterCategory$s.asObservable();
  otherCategory$w = this.otherCategory$s.asObservable();
  otherSubCategory$w = this.otherSubCategory$s.asObservable();
  selCategoryList$w = this.selCategoryList$s.asObservable();
  isClearSelectedLogs$w = this.isClearSelectedLogs$s.asObservable();

  snapshotPermission$w = this.snapshotPermission$s.asObservable();
  isReadyFilteredLogs$w = this.isReadyFilteredLogs$s.asObservable();

  isBuildReportMode$w = this.isBuildReportMode$s.asObservable();
  isOpenDetailModal$w = this.isOpenDetailModal$s.asObservable();

  lastLogIdInOriginLogList$w = this.lastLogIdInOriginLogList$s.asObservable();

  xmlEventCodeList$w = this.xmlEventCodeList$s.asObservable();
  selectedDate$w = this.selectedDate$s.asObservable();



  // -----------------------------------------------

  async initAllSettings(){
    const eventCategory = [
      { name : 'Armed / Disarmed', label: 'arming', checked: true, value: null, isArmedCategory: true, disabled: false , isBeta: false },
      { name : 'Alarm Video', label: 'alarm-video', checked: true, value: [], isArmedCategory: false, disabled: false , isBeta: false },
      { name : 'Playback Video', label: 'playback', checked: false, value: [], isArmedCategory: false, disabled: false, isBeta: false },
      { name : 'Automation Action', label: 'automation', checked: false, value: [], isArmedCategory: false, disabled: false, isBeta: true },
      { name : 'Manual Relay Event', label: 'relay', checked: false, value: null, isArmedCategory: false, disabled: false , isBeta: false,},
      { name : 'Manual Audio Broadcast', label: 'audio', checked: false, value: null, isArmedCategory: false, disabled: false , isBeta: false,},
      { name : 'Time-lapse Event', label: 'timelapse', checked: false, value: null, isArmedCategory: false, disabled: false , isBeta: false,},
      { name : 'Disarmed Event', label: 'disarmed-event', checked: false, value: [], isArmedCategory: false, disabled: false , isBeta: false,},
    ]
    const eventFilterCategory = [
      { name : 'True', label: 'video-ai', checked: true, value: null, disabled: false, isBeta: false },
      { name : 'False', label: 'video-ai', checked: false, value: null, disabled: false, isBeta: false },
      { name : 'Not Analyzed', label: 'video-ai', checked: true, value: null, disabled: false, isBeta: false },
      { name : 'Failed', label: 'video-ai', checked: true, value: null, disabled: false, isBeta: false },
      { name : 'Verification Request', label: 'verification', checked: false, value: null, disabled: false, isBeta: false},
      { name : 'Operator Viewed', label: 'operator-viewed', checked: false, value: null, disabled: false, isBeta: false},
    ]
    const otherCategory = [
      // { name : 'Operator Viewed', label: 'operator-viewed', checked: false, value: null, isArmedCategory: false},
      // { name : 'Trouble', label: 'trouble', checked: false, value: null, isArmedCategory: false},
      { name : 'Others', label: 'default', checked: false, value: [], disabled: false, isBeta: false, isUnfold: false, selectCount: 0, subCategory: this.otherSubCategory$s.value },
    ]

    this.isEnoughInit$s.next(false)
    this.snapshotPermission$s.next(false)
    this.isReadyFilteredLogs$s.next(false)
    this.isOpenDetailModal$s.next({status: false, log: null})
    this.selectedDate$s.next('Today')

    this.todayInTimezone = moment().tz(this.sitesService.selSite.timezone)
    this.today = this.todayInTimezone.clone().format('MMM / DD / YYYY')

    this.setEventCategory(eventCategory)
    this.setEventFilterCategory(eventFilterCategory)
    this.setOtherCategory(otherCategory)
    this.setOtherSubCategory([])
    this.setLastLogIdInOriginLogList('')
    this.setXmlEventCodeList([])

    this.setFilteredLogs([])
    this.clearSelectedLogs()
    this.setSelCategoryList([])

    await Promise.all([
      this.setOperatorMemberIds(),
      this.fetchEventCounts()
    ])
    this.eventListByDate = {}
  }
  //
  public getIsEnoughInit(): boolean{
    return this.isEnoughInit$s.value
  }
  public getIsSortOrder(): string{
    return this.sortOrder$s.value
  }
  public getSnapshotPermission(): boolean {
    return this.snapshotPermission$s.value
  }
  public getXmlEventCodeList(): any[] {
    return this.xmlEventCodeList$s.value
  }
  //
  public getSelectedDate(){
    return this.selectedDate$s.value
  }
  public setSelectedDate(value) {
    return this.selectedDate$s.next(value)
  }
  //
  //
  public getFilteredLogsLength(): number{
    return this.filteredLogs$s.value?.length ?? 0
  }
  public getSelectedLogLength(): number {
    return this.selectedLogs$s.value?.length ?? 0
  }
  //
  public getEventCategory(): any[] {
    const eventCategory = this.eventCategory$s.value;
    return eventCategory.slice()
  }
  public getEventFilterCategory(): any[] {
    const eventFilterCategory = this.eventFilterCategory$s.value;
    return eventFilterCategory.slice()
  }
  public getOtherCategory(): any[] {
    const otherCategory = this.otherCategory$s.value;
    return otherCategory.slice()
  }
  public getOtherSubCategory(): any[] {
    const otherSubCategory = this.otherSubCategory$s.value;
    return otherSubCategory.slice()
  }
  public getSelCategoryListLength(): number {
    return this.selCategoryList$s.value?.length ?? 0
  }
  public getSelCategoryList(): any[] {
    const selCategoryList = this.selCategoryList$s.value;
    return selCategoryList.slice()
  }
  private async setOperatorMemberIds(){
    try {
      await this.helper.me.load_my_dealer_only_if_not_loaded()
      const dealerId = this.usersService.me?.dealer_id
      const memberList = await this.usersService.getUsers(dealerId).toPromise()
      const roleList = await this.rolesService.getRoles(dealerId).toPromise()
      const operatorGroup = roleList.filter(v => {
        if(!v?.name) return false
        if(v.name?.toLowerCase()?.includes('operator')) return true
      })
      const operatorGroupIds = operatorGroup.map(v => v.role_id)
      // Monitoring Operator 그룹이거나, ip user인 경우는 operator인 것으로 간주.
      const operatorMemberList = memberList.filter(v => operatorGroupIds.includes(v.role_id) || v.provider === 'ip')
      const operatorMemberIds = operatorMemberList.map(v => v.user_id)
  
      this.operatorMemberIds = operatorMemberIds
    } catch(err) {
      console.debug('No permission for Members/Roles apis')
      this.operatorMemberIds = []
    }
  }

  public async fetchEventCounts(currentDate?){
    try {
      const dealerId = await this.helper.me.get_my_dealer_id()
      const siteId = this.sitesService.selSite.site_id

      console.log('prev', this.selectedDate$s.value, currentDate)
      let stime = null
      let etime = null
      if(currentDate) {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const siteTimezone = this.sitesService.selSite.timezone
        stime = moment().year(currentYear).month(currentMonth).tz(siteTimezone).startOf('month').unix();
        etime = moment().year(currentYear).month(currentMonth).tz(siteTimezone).endOf('month').unix();
      } else {
        let selectedDate = this.selectedDate$s.value
        if(this.selectedDate$s.value === 'Today') selectedDate = this.today
        stime = moment(selectedDate).startOf('month').unix();
        etime = moment(selectedDate).endOf('month').unix();
      }

      const types = 11 // video events
      const { video_event_count } = await this.sitesService.getSiteStatistics(dealerId, siteId, stime, etime, types).toPromise()
      this.parseEventCounts(video_event_count)
    } catch(err) {
      console.debug('fetchEventCounts:>',err)
    }
  }

  parseEventCounts(eventList){
    if(!eventList) return this.eventListByDate = {};
    if(!(eventList instanceof Object)) return this.eventListByDate = {};
    
    this.eventListByDate = {};
    for (const timestamp in eventList) {
      const siteTimezone = this.sitesService.selSite.timezone
      const date = moment(parseInt(timestamp) * 1000).tz(siteTimezone).date().toString()
      if (!this.eventListByDate[date]) {
        this.eventListByDate[date] = 0;
      }
      this.eventListByDate[date] += parseInt(eventList[timestamp]);
    }
  }

  // --------------------------------
  public setIsEnoughInit(logs): void{
    this.isEnoughInit$s.next(logs)
  }
  public setSnapshotPermission(value): void {
    this.snapshotPermission$s.next(value)
  }
  public setLastLogIdInOriginLogList(value){
    if(!value) {
      this.lastLogIdInOriginLogList$s.next('')
      return
    }

    const isAsc = this.getIsSortOrder() === 'Ascending'
    let input = ''
    isAsc
      ? input = (parseInt(value) + 1).toString()
      : input = (parseInt(value) - 1).toString()
    this.lastLogIdInOriginLogList$s.next(input)
  }
  public setXmlEventCodeList(value): void {
    this.xmlEventCodeList$s.next(value)
  }
  public setFilteredLogs(logs): void{
    this.isReadyFilteredLogs$s.next(false)
    this.filteredLogs$s.next(logs)
    this.sortLogs()
    this.isReadyFilteredLogs$s.next(true)
  }
  public changeSortOrder(sortOrder): void{
    this.sortOrder$s.next(sortOrder)
  }
  // --------------------------------------------
  public changeBuildReportMode(value): void{
    this.isBuildReportMode$s.next(value)
  }
  public openDetailModal(value): void{
    this.isOpenDetailModal$s.next(value)
  }
  //
  public clearSelectedLogs(): void{
    this.isClearSelectedLogs$s.next(true)
    this.selectedLogs$s.next([])
  }
  public addSelectedLogs(log): void{
    const value = this.selectedLogs$s.value
    value.push(log)

    const isClearSelectedLogs = this.isClearSelectedLogs$s.value
    if(isClearSelectedLogs) this.isClearSelectedLogs$s.next(false)
    this.sortSelectedLogs(value)
  }
  public deleteSelectedLogs(log): void{
    const value = this.selectedLogs$s.value
    const targetIdx = value.findIndex(v => v === log)
    value.splice(targetIdx, 1);

    if(!value.length) this.isClearSelectedLogs$s.next(true)
    this.selectedLogs$s.next(value)
  }
  //
  public setEventCategory(value): void {
    this.eventCategory$s.next(value);
  }
  public setEventFilterCategory(value): void {
    this.eventFilterCategory$s.next(value);
  }
  public setOtherCategory(value): void {
    this.otherCategory$s.next(value);
  }
  public setOtherSubCategory(value): void {
    this.otherSubCategory$s.next(value);
  }

  public setSelCategoryList(value): void {
    console.log('setSelCategoryList:>',value)
    this.selCategoryList$s.next(value);
  }

  // --------------------------------------------
  // PARSE LOGS
  // 1. for category
  public setCategoryId(category){
    const name = category.name.toLowerCase()
    const eventCategory = this.getEventCategory()
    const eventFilterCategory = this.getEventFilterCategory()
    const otherCategory = this.getOtherCategory()
    const otherSubCategory = this.getOtherSubCategory()

    try {
      switch(name){
        case('video event') : 
          eventCategory.find(v=> v.label === 'alarm-video').value.push(category.id)
          eventCategory.find(v=> v.label === 'automation').value.push(category.id)
          this.setEventCategory(eventCategory)
          break
        case('arming') : 
          eventCategory.find(v=> v.label === 'arming').value = category.id
          this.setEventCategory(eventCategory)
          break
        case('playback event') : 
          eventCategory.find(v=> v.label === 'playback').value.push(category.id)
          eventCategory.find(v=> v.label === 'alarm-video').value.push(category.id)
          this.setEventCategory(eventCategory)
          break
        case('relay') : 
          eventCategory.find(v=> v.label === 'relay').value = category.id
          this.setEventCategory(eventCategory)
          break
        case('audio') : 
          eventCategory.find(v=> v.label === 'audio').value = category.id
          eventCategory.find(v=> v.label === 'automation').value.push(category.id)
          this.setEventCategory(eventCategory)
          break
        case('timelapse event') : 
          eventCategory.find(v=> v.label === 'timelapse').value = category.id
          eventCategory.find(v=> v.label === 'alarm-video').value.push(category.id)
          this.setEventCategory(eventCategory)
          break
        case('disarmed event') : 
          eventCategory.find(v=> v.label === 'disarmed-event').value.push(category.id)
          eventCategory.find(v=> v.label === 'alarm-video').value.push(category.id)
          this.setEventCategory(eventCategory)
          break
        case('sensor') : 
          eventFilterCategory.forEach(v => {
            if(v.label === 'video-ai') v.value = category.id
          })
          eventCategory.find(v=> v.label === 'alarm-video').value.push(category.id)
          eventCategory.find(v=> v.label === 'disarmed-event').value.push(category.id)

          const otherCategoryTarget = otherCategory.find(v=> v.label === 'default')
          otherCategoryTarget.value.push(category.id)
          const sensorObj = { name : name, label: 'default', color: '#D8D8D8', checked: false, value: category.id }
          otherSubCategory.push(sensorObj)
          otherCategoryTarget.subCategory = otherSubCategory

          this.setEventFilterCategory(eventFilterCategory)
          this.setEventCategory(eventCategory)
          this.setOtherCategory(otherCategoryTarget)
          this.setOtherSubCategory(otherSubCategory)
          break
        case('verification') : 
          eventFilterCategory.find(v => v.label === 'verification').value = category.id
          eventCategory.find(v => v.label === 'alarm-video').value.push(category.id)
          eventCategory.find(v=> v.label === 'playback').value.push(category.id)
          this.setEventFilterCategory(eventFilterCategory)
          this.setEventCategory(eventCategory)
          break
        // case('trouble') : 
        //   otherCategory.find(v=> v.label === 'trouble').value = category.id
        //   this.setOtherCategory(otherCategory)
        //   break
        case('alarm') : break
        case('live video') : break
        case('periodic test') : break
        case('end user live view') : break
        case('walk test') : break
        default : 
          if(name === 'bridge') eventCategory.find(v=> v.label === 'automation').value.push(category.id)

          const target = otherCategory.find(v=> v.label === 'default')
          target.value.push(category.id)

          const obj = { name : name, label: 'default', color: '#D8D8D8', checked: false, value: category.id }
          otherSubCategory.push(obj)
          target.subCategory = otherSubCategory

          this.setEventCategory(eventCategory)
          this.setOtherCategory(otherCategory)
          this.setOtherSubCategory(otherSubCategory)
          break
      }
    } catch(err) {
      console.log('setCategoryId:>',err)
    }
    const target = otherCategory.find(v=> v.label === 'default')
    let other = otherSubCategory.sort((a, b) => this.compare(a.name, b.name, true))
    target.subCategory = other

    this.setOtherSubCategory(other)
    this.setOtherCategory(otherCategory)
  }

  public setLogFilter(startDate: string, endDate: string) {
    let filters = [];
    let filter = '';
    startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')

    this.startDate = startDate

    // 하루씩 호출하기
    filters.push(`log.created,${startDate},gte`) // > startDate
    filters.push(`log.created,${endDate},lte`) // < endDate
    const listLogId = this.lastLogIdInOriginLogList$s.value // 같은 시간 로그라도 놓치지 않을 수 있음
    if(listLogId) {
      const isAsc = this.getIsSortOrder() === 'Ascending'
      isAsc
        ? filters.push(`log.id,${listLogId},gte`)
        : filters.push(`log.id,${listLogId},lte`)
    }

    // Category Filter
    const selCategoryList = this.selCategoryList$s.value
    if (selCategoryList.length !== 0) {
      let categoryFilterStr = '';
      selCategoryList.forEach((category, idx) => {
        if(!category?.value) return
        if (idx === 0) {
          categoryFilterStr = `log_category.id,${category['value']}`;
        } else {
          const categoryList = categoryFilterStr.split(',')[1]
          const categoryListArr = categoryList.split('||')
          const categoryValueList = category['value'].toString().split('||')
        
          const newCategoryList = categoryValueList
            .filter(value => !categoryListArr.includes(value))
            .join('||');
          if(newCategoryList) categoryFilterStr += `||${newCategoryList}`
        }
      })
      filters.push(categoryFilterStr)
      console.log('categoryFilterStr', categoryFilterStr)
    }
    if (!selCategoryList.length) {
      filters.push(`log_category.id,-1`)
    }

    filters.forEach(f => {
      filter += `filter=${f}&`;
    })
    filter = filter.slice(0,-1);
    filter += '&limit=1000'
    return filter;
  }

  private capitalizeFirstLetters(str) {
    if(!str) return ''
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  // for log
  public setLogs(logs) {
    logs.forEach(log => {
      if(!log.event_time) log.event_time = log.created
      log.localCreated = moment(log.created).utc().tz(this.sitesService.selSite.timezone).format('MM/DD/YY hh:mm:ss a');
      log.eventCreated = moment(log.event_time).utc().tz(this.sitesService.selSite.timezone).format('MM/DD/YY hh:mm:ss a')
      
      log.isLoading = true
      log.isSelected = false

      log.imageUrl = null
      log.isPrivacy = false
      log.is_expired = false
      log.imageUrl = null
      log.videoUrl = null
      log.safetyVideoUrl = null

      log.eventCodeList = []

      log.isSnapshotUpload = false
      log.isVideoUpload = false
      log.videoLogId = log.id

      log.status_text = this.capitalizeFirstLetters(log.status_text)
      log.videoAILogId = null
      log.videoAIResult = ''

      log.isHasVerification = false
      log.isAutomation = false
      log.isDisarmedEvent = false
      try {
        log.info = JSON.parse(log.meta).info
        log.res = JSON.parse(log.meta).res
        log.req = JSON.parse(log.meta).req
        log.zone = log.info.zone
      } catch {
        // console.log(log)
        log.info = {};
        log.res = {};
        log.req = {};
        log.zone = '';
      }
    })

    if(!logs?.length) return []

    const excludeLogs = this.excludeBeforeSelectedDate(logs)
    const groupedList = this.groupingByEventId(excludeLogs)
    const filteredList = this.separateFilteredByCategoryLogs(groupedList)
    const result = this.filteredUnusedLogs(filteredList)
    console.debug('🏁 End in Service')
    // console.debug(filteredList)
    return result
  }

  // -------------------------------------------------------
  // STEP 0: event_time이 selected date보다 이전일 경우 제외 -----
  private excludeBeforeSelectedDate(logs): any[]{
    const result = logs.filter(log => {
      const startDate = moment.utc(this.startDate)
      const logEventTime = moment(log.created)
      if(moment(logEventTime).isBefore(startDate)) return false
      return true
    })
    return result
  }

  // -------------------------------------------------------
  // STEP 1: eventId 기준으로 묶는 로직 -------------------------
  private groupingByEventId(logs): any[]{
    let eventIdList: eventIdListEl[] = []
    let audioLogIdList: audioLogIdListEl[] = []
    let relayLogIdList: relayLogIdListEl[] = []
    logs = logs.sort((a, b) => this.compare(a.event_time, b.event_time, true))

    const result = {}
    // console.log(logs)
    for(let i = 0; i < logs.length; i++){
      const log = logs[i]

      // CASE 1 - For Automation Action : Bridge & Audio
      if(this.isAudio(log)) {
        this.groupingAudioLog(result, log, audioLogIdList)
        continue // - END
      } 

      // CASE 2 - For Automation Action : Bridge & Audio
      if(this.isBridge(log)) {
        log.isAutomation = this.isAutomationCheckingByDescription(log)
        // - not END
      } 

      // CASE 3 - For Relay Event
      if(this.isRelay(log)) {
        this.groupingRelayLogs(result, log, relayLogIdList)
        continue // - END
      }

      // CASE 4 - Video AI & Verification & Other with event ID
      if(!log.event_id) {
        if(this.isPlaybackEvent(log)) continue
        if(this.isSensor(log) && log.trigger?.toLowerCase() == 'cloud api') continue 
        if(this.isDisarmedEvent(log)) continue 
        result[log.id] = log
        continue // - END
      } 
      this.groupingLogsByEventId(result, log, eventIdList)
    }
    // console.debug(Object.values(result)) 
    return Object.values(result)
  }

  // GROUPING by event id START -----------------------------
  private groupingLogsByEventId(result, log, eventIdList: eventIdListEl[]) {
    // console.log('start',log.event_id, log.id, log.category, log.status_text, log.trigger, this.isVideoAILog(log))
    const includedInEventIdListIdx = eventIdList.findIndex(v => v.eventId === log.event_id);
  
    if (includedInEventIdListIdx > -1) {
      this.updateExistingEvent(result, log, eventIdList, includedInEventIdListIdx);
    } else {
      this.createAndAddNewEvent(result, log, eventIdList);
    }
  }
  
  private updateExistingEvent(result, log, eventIdList: eventIdListEl[], idx) {
    const target: eventIdListEl = eventIdList[idx];
    // console.debug(log.event_id, log.id, log.category, target.isHasVerification, log.status_text, this.videoAIResult(log))
  
    if(this.mergeTimelapseLogs(log, target)) log = this.mergeTimelapseLogs(log, target)
    if(this.mergePlaybackEventLogs(log, target)) log = this.mergePlaybackEventLogs(log, target)
    if(this.mergeDisarmedEventLogs(log, target)) log = this.mergeDisarmedEventLogs(log, target)
    this.handleVerification(log, target);
    this.handleVideoAI(log, target);
    this.handleAutomationAction(log, target);
    this.handleDisarmedEvent(log, target);
    this.handleTimelapse(log, target);
    this.handleUploadStatus(log, target);
    this.handleViewedList(log, target);
    this.handleXMLEventCode(log, target);
  
    // LAST SET - 덮어쓰기 방지
    if (target.isHasVerification) {
      log.isHasVerification = true;
    }
    if (target.eventTime) {
      log.event_time = target.eventTime
      log.created = target.storageTime
      log.localCreated = moment(log.created).utc().tz(this.sitesService.selSite.timezone).format('MM/DD/YY hh:mm:ss a');
      log.eventCreated = moment(log.event_time).utc().tz(this.sitesService.selSite.timezone).format('MM/DD/YY hh:mm:ss a')
    }
  
    result[log.event_id] = log;
    // console.log('🏁',log.isHasVerification, log.event_id, result[log.event_id])
  }
  
  private createAndAddNewEvent(result, log, eventIdList) {
    const obj: eventIdListEl = this.initializeEventObject(log);
  
    this.updateEventObjectWithLogInfo(obj, log);
    eventIdList.push(obj);
  
    result[log.event_id] = log;
  }
  
  private initializeEventObject(log): eventIdListEl {
    return {
      eventId: log.event_id,
      videoLogId: log.id,

      eventTime: log.event_time, // 2021-07-01T00:00:00.000Z
      storageTime: log.created, // 2021-01-07T00:12:22

      lastEventType: log.category,
      videoAILogId: '',
      videoAIResult: '',
      statusText: '',

      email: log.email,
      ip: log.ip,
      userId: log.user_id,
      eventCode: log.event_code ? [{code: log.event_code, name: 'No Name'}] : [],
      isSendXmlSignal: log.is_send_xml_signal,
      meta: null,
      isSnapshotUpload: log.res.is_snapshot_upload,
      isVideoUpload: log.res.is_video_upload,
      isHasVerification: false,
      isAutomation: false,
      isDisarmedEvent: this.isDisarmedEvent(log),
      isTimelapse : this.isTimelapse(log),
      isPlayback : this.isPlaybackEvent(log),
      log: null
    };
  }
  
  private updateEventObjectWithLogInfo(eventObject: eventIdListEl, log) {
    if (this.isVideoAILog(log)) {
      eventObject.videoAILogId = log.id;
      eventObject.videoAIResult = this.videoAIResult(log);
      eventObject.statusText = log.status_text;
      eventObject.meta = log.meta;
      log.category = eventObject.isDisarmedEvent ? 'Disarmed Event' : 'Video Event';
    }
    if (this.isVerification(log)) {
      eventObject.isHasVerification = true;
      log.isHasVerification = true;
    }
    if (this.isAutomationCheckingByDescription(log)) {
      eventObject.isAutomation = true;
      log.isAutomation = true;
    }
    if (this.isDisarmedEvent(log)){
      eventObject.isDisarmedEvent = true;
      log.isDisarmedEvent = true;
      eventObject.log = log
    }
    if (this.isPlaybackEvent(log)) {
      eventObject.log = log
    }
    if (this.isTimelapse(log)) {
      eventObject.isSnapshotUpload = log.req.body.has_snapshots;
      eventObject.isVideoUpload = log.req.body.has_video;
      log.isSnapshotUpload = log.req.body.has_snapshots;
      log.res.is_snapshot_upload = log.req.body.has_snapshots;
      log.isVideoUpload = log.req.body.has_video;
      log.res.is_video_upload = log.req.body.has_video;
      eventObject.log = log
    }
    if (log.event_code) {
      log.eventCodeList = [{ code: log.event_code, name: 'No Event Code' }]
    }
  }

  private mergeTimelapseLogs(log, target: eventIdListEl) {
    const isVideoUploadLog = this.isVideoEvent(log) && log.status_text.toLowerCase() === 'video uploaded'
    if (target.isTimelapse && isVideoUploadLog) {
      // timelapse - video uploaded 연결된 것은 하나로
      return target.log
    }
  }
  private mergePlaybackEventLogs(log, target: eventIdListEl) {
    const isVideoUploadLog = this.isVideoEvent(log) && log.status_text.toLowerCase() === 'video uploaded'
    if (target.isPlayback && isVideoUploadLog) {
      // playback - video uploaded 연결된 것은 하나로
      return target.log
    }
  }

  private mergeDisarmedEventLogs(log, target: eventIdListEl) {
    const isVideoEvent = this.isVideoEvent(log)
    if (target.isDisarmedEvent && isVideoEvent) {
      // disarmed - disarmed 연결된 것은 하나로
      return target.log
    } 
  }
  
  private handleVerification(log, target: eventIdListEl) {
    if (this.isVerification(log)) {
      target.isHasVerification = true;
      log.isHasVerification = true;
      log.category = target.lastEventType.toLowerCase() === 'sensor' ? 'Video event' : target.lastEventType
    }
  }
  
  private handleVideoAI(log, target: eventIdListEl) {
    if (this.isVideoAILog(log) && !target.videoAILogId) {
      target.videoAILogId = log.id;
      target.videoAIResult = this.videoAIResult(log);
      target.statusText = log.status_text;
      target.meta = log.meta;

      log.category = target.isDisarmedEvent ? 'Disarmed Event' : 'Video Event';
      log.videoAILogId = log.id;
      log.videoAIResult = this.videoAIResult(log);
      log.status_text = log.status_text;
    }
    if (!this.isVideoAILog(log) && target.videoAILogId) {
      log.category = target.isDisarmedEvent ? 'Disarmed Event' : 'Video Event';
      log.videoAILogId = target.videoAILogId;
      log.videoAIResult = target.videoAIResult;
      log.status_text = target.statusText;
      log.meta = target.meta;
      log.trigger = 'VideoAI';
    }
  }
  private handleAutomationAction(log, target: eventIdListEl) {
    if(
      !this.isVideoEvent(log) &&
      !this.isRelay(log) &&
      !this.isPlaybackEvent(log) 
      // !this.isBridge(log) // event id가 없어서 위에서 처리
      // !this.isAudio(log) // audio는 별개의 로직으로 진행됨 ('by rule'로 확인)
    ) return

    // other CASE
    if(this.isAutomationCheckingByDescription(log) && !target.isAutomation) {
      target.isAutomation = true
      log.isAutomation = true
    }
    if(!this.isAutomationCheckingByDescription(log) && target.isAutomation) {
      log.isAutomation = target.isAutomation
    }
  }

  private handleDisarmedEvent(log, target: eventIdListEl) {
    if(this.isDisarmedEvent(log)) {
      target.isDisarmedEvent = true
      log.isDisarmedEvent = true
      target.log = log
    }
    if(target.isDisarmedEvent) {
      log.isDisarmedEvent = true
      target.log = log
    }
  }
  
  private handleTimelapse(log, target: eventIdListEl) {
    if (this.isTimelapse(log)) {
      target.isSnapshotUpload = log.req.body.has_snapshots;
      target.isVideoUpload = log.req.body.has_video;
      log.isSnapshotUpload = log.req.body.has_snapshots;
      log.res.is_snapshot_upload = log.req.body.has_snapshots;
      log.isVideoUpload = log.req.body.has_video;
      log.res.is_video_upload = log.req.body.has_video;
    }
  }
  
  private handleUploadStatus(log, target: eventIdListEl) {
    if (log.res.is_snapshot_upload) target.isSnapshotUpload = true;
    if (log.res.is_video_upload) {
      target.isVideoUpload = true;
      target.videoLogId = log.id;
    }
    if (!log.res.is_snapshot_upload && target.isSnapshotUpload) log.isSnapshotUpload = true;
    if (!log.res.is_video_upload && target.isVideoUpload) {
      log.isVideoUpload = true;
      log.videoLogId = target.videoLogId;
    }
  }

  private handleViewedList(log, target: eventIdListEl) {
    // viewed에 첫번째 시청자가 표시되도록 하기
    if(log.email && !target.email) {
      target.email = log.email
      target.ip = log.ip
      target.userId = log.user_id
    }
    if(target.email) {
      log.email = target.email
      log.ip = target.ip
      log.user_id = target.userId
    }
  }

  private handleXMLEventCode(log, target: eventIdListEl | audioLogIdListEl | relayLogIdListEl){
    if(log.is_send_xml_signal && !target.isSendXmlSignal) {
      target.isSendXmlSignal = log.is_send_xml_signal
    }
    if(!log.is_send_xml_signal && target.isSendXmlSignal) {
      log.is_send_xml_signal = target.isSendXmlSignal
    }

    if(log.event_code) {
      const isHad = target.eventCode?.find(v => v.code === log.event_code)
      const obj = { code: log.event_code, name: 'No Event Code'}
      if(!isHad) target.eventCode.push(obj)
    }
    log.eventCodeList = target.eventCode
  }
  // GROUPING by event id START -----------------------------

  // PARSING for audio START -----------------------------
  private groupingAudioLog(result, log, audioLogIdList: audioLogIdListEl[]) {
    const isAutomation = this.isAutomationActionAudio(log);
    const deviceId = log.device_id;
    const includedAudioLogIdListIdx = this.findLogIndexByDeviceId(audioLogIdList, deviceId);
    
    if (includedAudioLogIdListIdx === -1) {
      this.handleNewLog(result, log, audioLogIdList, isAutomation);
      return;
    }

    this.handleExistingLog(result, log, audioLogIdList, includedAudioLogIdListIdx, isAutomation);
  }

  private handleNewLog(result, log, audioLogIdList, isAutomation) {
    if (log.event_id) {
      log.isAutomation = isAutomation;
      result[log.id] = log;
      return;
    }

    const obj: audioLogIdListEl = { 
      lastLogId: log.id, 
      deviceId: log.device_id, 
      eventCode: log.event_code ? [{code: log.event_code, name: 'No Name'}] : [],
      isSendXmlSignal: log.is_send_xml_signal,
      isAutomation, 
      log, 
      logList: [log] 
    };
    audioLogIdList.push(obj);
    log.isAutomation = isAutomation;
    result[log.id] = log;
  }

  private handleExistingLog(result, log, audioLogIdList, idx, isAutomation) {
    const target = audioLogIdList[idx];
    this.handleXMLEventCode(log, target);

    if (log.event_id) {
      this.processPlaybackLog(result, log, audioLogIdList, target);
    } else {
      this.processNonPlaybackLog(result, log, audioLogIdList, target, isAutomation);
    }
  }

  private processNonPlaybackLog(result, log, audioLogIdList, target, isAutomation) {
     // 그런데 이전 1초 내 동일한 장치에서에 발생한 것이라면
    const timeDifference = (new Date(target.log.event_time).getTime() - new Date(log.event_time).getTime()) / 1000;

    if (timeDifference <= 0.5 && timeDifference >= -0.5) {
      // 여러개가 겹칠 수 있으니 따로 보관
      target.logList.push(log);
      // return;
    }

    this.updateResult(result, target, isAutomation);
    this.updateOrRemoveTargetLog(audioLogIdList, target, log);
  }

  private processPlaybackLog(result, log, audioLogIdList, target) {
    log.isAutomation = target.isAutomation;
    result[target.lastLogId] = log;
    this.updateOrRemoveTargetLog(audioLogIdList, target, log);
  }

  private updateResult(result, target, isAutomation) {
    target.isAutomation = isAutomation;
    target.log.isAutomation = isAutomation;
    result[target.lastLogId] = target.log;
  }

  private updateOrRemoveTargetLog(audioLogIdList, target, log) {
    if (target.logList.length === 1) {
      audioLogIdList.splice(audioLogIdList.indexOf(target), 1);
    } else {
      if (
        target.logList[0].event_id && !log.event_id ||
        !target.logList[0].event_id && log.event_id
      ) target.logList.shift();
      
      target.log = target.logList[0];
      target.lastLogId = target.log.id;
      target.isAutomation = this.isAutomationActionAudio(target.log);
    }
  }

  private findLogIndexByDeviceId(audioLogIdList, deviceId) {
    return audioLogIdList.findIndex(v => v.deviceId === deviceId);
  }
  // PARSING for audio END -----------------------------
  // -------------------------------------------------------

  // PARSING for relay START -----------------------------
  private groupingRelayLogs(result, log, relayLogIdList: relayLogIdListEl[]) {
    /**
     * 다음은 무시해도 괜찮을 것으로 보임
      - Latching

      Energize → Energized
      Energize with timer → Energized with timer : Momentary와 함께할 수도 있므
      Deenergize → Deenergized
      Deenergize with timer → Deenergized with timer
      normal: 이것은 별도로 표시
      timer : 이것은 별도로 표시
     */

    if(log.status_text?.toLowerCase() === 'latching') return
    if(log.status_text?.toLowerCase() === 'normal' || log.status_text?.toLowerCase() === 'timer') {
      result[log.id] = log;
      return;
    }

    const deviceId = log.device_id;
    const includedRelayLogIdListIdx = this.findLogIndexByDeviceId(relayLogIdList, deviceId);
    const statusTxt = log.status_text.toLowerCase();
    
    if (includedRelayLogIdListIdx === -1) {
      this.handleNewRelayLog(result, log, relayLogIdList, statusTxt);
      return;
    }

    this.handleRelayExistingLog(result, log, relayLogIdList, includedRelayLogIdListIdx, statusTxt);
  }

  private setMomentaryDelay(log): number {
    // momentary 내용 저장
    if(!this.isMomentaryDelay(log)) return null
    const delayTimes = log?.req?.body?.delay_times
    return delayTimes ?? null
  }

  private handleNewRelayLog(result, log, relayLogIdList, statusTxt) {
    const firstWordInStatusTxt = statusTxt.split(' ')[0].toLowerCase();
    if (firstWordInStatusTxt.includes('ed')) {
      result[log.id] = log;
      return;
    }

    const momentaryDelay = this.setMomentaryDelay(log);
    const obj: relayLogIdListEl = { 
      lastLogId: log.id, 
      deviceId: log.device_id, 
      momentaryDelay: momentaryDelay,
      momentaryEngagedIcon: !this.isMomentaryDelay(log) ? log.meta: null,
      eventCode: log.event_code ? [{code: log.event_code, name: 'No Name'}] : [],
      isSendXmlSignal: log.is_send_xml_signal,
      log, 
      logList: [log] 
    };
    relayLogIdList.push(obj);
    result[log.id] = log;
  }

  private handleRelayExistingLog(result, log, relayLogIdList, idx, statusTxt) {
    const target = relayLogIdList[idx];
    this.handleXMLEventCode(log, target);

    const momentaryDelay = this.setMomentaryDelay(log);
    target.momentaryDelay = momentaryDelay;
    if(!this.isMomentaryDelay(log)) target.momentaryEngagedIcon = log.meta;

    if(!this.isEndOfRelay(log)) {
      this.processGroupingRelayLog(result, log, relayLogIdList, target);
    } else {
      this.endGroupingRelayLog(result, log, relayLogIdList, target);
    }
  }

  private processGroupingRelayLog(result, log, relayLogIdList, target) {
    // 그런데 이전 1초 내 동일한 장치에서에 발생한 것이라면
    const timeDifference = (new Date(target.log.event_time).getTime() - new Date(log.event_time).getTime()) / 1000;

    if (timeDifference <= 0.5 && timeDifference >= -0.5) {
      // 여러개가 겹칠 수 있으니 따로 보관
      target.logList.push(log);
      return;
    }

    this.updateResultForRelay(result, target);
    this.updateOrRemoveTargetRelayLog(relayLogIdList, target);
  }
  private endGroupingRelayLog(result, log, relayLogIdList, target) {
    const delayTimes = target.momentaryDelay
    if(delayTimes) {
      log.meta = target.momentaryEngagedIcon
      log.status_text = `Momentary ${delayTimes}s - Energized`
    }

    result[target.lastLogId] = log;
    this.updateOrRemoveTargetRelayLog(relayLogIdList, target);
  }

  private updateResultForRelay(result, target) {
    result[target.lastLogId] = target.log;
  }

  private updateOrRemoveTargetRelayLog(relayLogIdList, target) {
    if (target.logList.length === 1) {
      relayLogIdList.splice(relayLogIdList.indexOf(target), 1);
    } else {
      target.logList.shift();
      target.log = target.logList[0];
      target.lastLogId = target.log.id;
    }
  }

  private isMomentaryDelay(log) {
    return log.status_text?.toLowerCase() === 'momentary'
  }

  private isEndOfRelay(log){
    const status = log.status_text?.toLowerCase()
    if(status === 'energized') return true
    if(status === 'deenergized') return true
    if(status === 'deenergized with timer') return true
    if(status === 'momentary') return true
    return false
  }

  // PARSING for relay END -----------------------------
  // -------------------------------------------------------

  // -------------------------------------------------------
  // STEP 2: 카테고리 hard coding 된 부분 분리하기 ---------------

  // 1. Video AI : Alarm Video 일 때만 발생 - 원래는 sensor에 포함됨
  // 2. Verification : Alarm Video, Playback Video 일 때 발생. 로직상 독립적 발생도 가능함
  // 3. Automation Action - 현재는 Audio, bridge에 대해서만
  private separateFilteredByCategoryLogs(logs: any[]) {
    const isCheckedOtherCategory = this.isCheckedCategory('other');
    const isCheckedSensorCategory = this.isCheckedCategory('sensor');

    const isCheckedVideoEventCategory = this.isCheckedCategory('alarm-video');
    const isCheckedArmingEventCategory = this.isCheckedCategory('arming');
    const isCheckedPlaybackEventCategory = this.isCheckedCategory('playback');
    const isAutomationActionEventCategory = this.isCheckedCategory('automation')
    const isDisarmedEventCategory = this.isCheckedCategory('disarmed-event')
    const isRelayCategory = this.isCheckedCategory('relay');
    const isAudioCategory = this.isCheckedCategory('audio');
    const isTimelapseEventCategory = this.isCheckedCategory('timelapse')
    const isCheckedVideoAICategory = this.isCheckedCategory('video-ai');
    const isCheckedVerificationCategory = this.isCheckedCategory('verification');
    const isCheckedOperatorViewedCategory = this.isCheckedCategory('operator-viewed');
  
    const result = logs.filter(log => {
      return this.filterLogByCategories(log, {
        isCheckedOtherCategory,
        isCheckedSensorCategory,
        isCheckedVideoEventCategory,
        isCheckedArmingEventCategory,
        isCheckedPlaybackEventCategory,
        isAudioCategory,
        isCheckedVerificationCategory,
        isRelayCategory,
        isTimelapseEventCategory,
        isCheckedVideoAICategory,
        isAutomationActionEventCategory,
        isDisarmedEventCategory,
        isCheckedOperatorViewedCategory
      });
    });
  
    return result;
  }
  
  private isCheckedCategory(categoryLabel: string): boolean {
    switch (categoryLabel) {
      case 'other':
        return this.getOtherCategory()[0]?.checked ?? false;
      case 'sensor':
        return this.getOtherSubCategory().find(v => v.name === 'sensor')?.checked ?? false;
      default:
        return this.getEventCategory().find(v => v.label === categoryLabel)?.checked ?? this.getEventFilterCategory().some(v => v.checked && v.label === categoryLabel) ?? false;
    }
  }
  
  private filterLogByCategories(log, categoryList) {
    const computedIsOther = !this.isVideoEvent(log) && !this.isVideoAILog(log) && !this.isPlaybackEvent(log) && !this.isVerificationEvent(log) && !this.isTimelapse(log) && !this.isSensor(log) && !this.isDisarmedEvent(log);
    const computedIsSensor = this.isSensor(log);
    const computedIsArming = this.isArmingEvent(log)
    const computedTimelapse = this.isTimelapse(log)
    const computedIsDisarmedEvent = this.isDisarmedEvent(log)

    const { 
      isCheckedOtherCategory,
      isCheckedSensorCategory,
      isCheckedVideoEventCategory,
      isCheckedVideoAICategory,
      isCheckedArmingEventCategory,
      isCheckedPlaybackEventCategory,
      isCheckedVerificationCategory,
      isTimelapseEventCategory,
      isDisarmedEventCategory,
      isAutomationActionEventCategory,
      isCheckedOperatorViewedCategory
    } = categoryList

    const automationActionLogs = (
      this.isAudio(log) 
      || this.isBridge(log) 
      || this.isRelay(log)
    )
    const filters = { 
      isCheckedVideoEventCategory, 
      isCheckedPlaybackEventCategory, 
      isAutomationActionEventCategory, 
      isCheckedVideoAICategory, 
      isCheckedVerificationCategory, 
      isCheckedOperatorViewedCategory
    }
  
    if (automationActionLogs) return this.separateAutomationActionLogs(log);
    if (this.isVideoEvent(log) || this.isPlaybackEvent(log)) return this.separateFilteringLogs(log, filters);
    if (isDisarmedEventCategory && computedIsDisarmedEvent) return this.separateFilteringDisarmedEventLogs(log, isCheckedVideoAICategory);
    if (isCheckedArmingEventCategory && computedIsArming) return true;
    if (isTimelapseEventCategory && computedTimelapse) return true;
    if (isCheckedSensorCategory && computedIsSensor) return true;
    if (isCheckedOtherCategory && computedIsOther) return true;
    if (
      !isCheckedVideoAICategory 
      && !isCheckedVideoEventCategory
      && !isCheckedArmingEventCategory
      && !isTimelapseEventCategory
      && !isCheckedSensorCategory
      && !isCheckedOtherCategory
      && !isCheckedVerificationCategory
      && !isCheckedPlaybackEventCategory
      && !isAutomationActionEventCategory
    ) return true;
    
    return false;
  }
  
  // Alarm Video만 로직에 맞게 필터링 하는 함수
  // 필터링 조건 함수들
  private filterByVideoAlarm(log, filters) {
    const isVideoEvent = this.isVideoEvent(log);
    // viewed 이벤트인 경우, 해당 일자에 해당하는 이벤트만 필터링
    const shouldIncludeViewedEvent = log.email // if it is viewed event
      ? log.isVideoUpload || log.isSnapshotUpload // is it occur today?
      : true; // pass
    const isVideoAI = this.isVideoAILog(log);
    const isVerificationEvent = this.isVerificationEvent(log);
    const isAutomationEvent = this.isAutomationEvent(log);
    const shouldFilterVideoAI = filters.isCheckedVideoAICategory ? this.showOnlySelectedVideoAIResult(log) : true; // video ai filter 해제면 다 포함
    const shouldFilterVerification = filters.isCheckedVerificationCategory ? isVerificationEvent : true; // verification 해제면 다 포함
    const isOperatorViewed = filters.isCheckedOperatorViewedCategory ? this.isOperatorViewed(log) : true

    return filters.isCheckedVideoEventCategory && isVideoEvent && shouldIncludeViewedEvent &&
      ((!isVideoAI && !filters.isCheckedVideoAICategory) || shouldFilterVideoAI) &&
      shouldFilterVerification && isOperatorViewed && !isAutomationEvent;
  }

  private filterByPlayback(log, filters) {
    const isOperatorViewed = filters.isCheckedOperatorViewedCategory ? this.isOperatorViewed(log) : true

    return filters.isCheckedPlaybackEventCategory && this.isPlaybackEvent(log) && 
      (filters.isCheckedVerificationCategory ? this.isVerificationEvent(log) : true) &&  // verification 해제면 다 포함
      isOperatorViewed &&
      !this.isAutomationEvent(log);
  }

  private filterByAutomationAction(log, filters) {
    const isOperatorViewed = filters.isCheckedOperatorViewedCategory ? this.isOperatorViewed(log) : true

    return filters.isAutomationActionEventCategory && this.isAutomationEvent(log) &&
    (filters.isCheckedVerificationCategory ? this.isVerificationEvent(log) : true) && isOperatorViewed
  }

  private separateFilteringLogs(log, filters){
    // alarm video, playback 둘다 들어옴
    if (this.filterByAutomationAction(log, filters)) return true;
    if (this.filterByVideoAlarm(log, filters)) return true;
    if (this.filterByPlayback(log, filters)) return true;
    return false;
  }

  private separateFilteringDisarmedEventLogs(log, isCheckedVideoAICategory){
    const isVideoAI = log.videoAILogId ? true : false;
    const shouldFilterVideoAI = isCheckedVideoAICategory ? this.showOnlySelectedVideoAIResult(log) : true; // video ai filter 해제면 다 포함
    return !isVideoAI || shouldFilterVideoAI
  }

  // Cloud AI만 필터링 하는 함수
  private showOnlySelectedVideoAIResult(log){
    const eventFilterCategory = this.getEventFilterCategory()
    const isCheckedVideoAITrue = eventFilterCategory.find(v => v.name === 'True')?.checked ?? false
    const isCheckedVideoAIFalse = eventFilterCategory.find(v => v.name === 'False')?.checked ?? false
    const isCheckedVideoAINotAnalyzed = eventFilterCategory.find(v => v.name === 'Not Analyzed')?.checked ?? false
    const isCheckedVideoAIFailed = eventFilterCategory.find(v => v.name === 'Failed')?.checked ?? false

    if(!this.isVideoEvent(log) && !log.isDisarmedEvent) return false
    const result = log.videoAIResult
    if(result === 'True' && isCheckedVideoAITrue) return true
    if(result === 'False' && isCheckedVideoAIFalse) return true
    if(result === 'Failed' && isCheckedVideoAIFailed) return true
    if(
      (result === 'Skipped' || !this.isVideoAILog(log) || result === 'Not Analyzed') && 
      isCheckedVideoAINotAnalyzed
    ) return true
    return false
  }
  private videoAIResult(log){
    const req = log.req
    if(!this.isVideoAILog(log)) return 'Not Analyzed'
    if(!req.body?.result) return 'Not Analyzed'

    // SKIPPED, FAILURE
    if(req.body?.result === 'skipped') return 'Skipped'
    if(req.body?.result === 'failure') return 'Failed'

    // SUCCESS
    const statusText = log?.status_text?.toLowerCase()
    if(!statusText) return 'Failed'
    if(statusText === 'alarm' || statusText === 'activity') return 'True'
    if(statusText === 'normal') return 'False'
    return 'Failed'
  }

  // automation rule만 필터링 : filter 메소드 내에서 사용
  private separateAutomationActionLogs(log){
    const isCheckedAudioCategory = this.getEventCategory().find(v => v.label === 'audio')?.checked ?? false
    const isCheckedBridgeCategory = this.getOtherSubCategory().find(v => v.name === 'bridge')?.checked ?? false
    const isCheckedRelayCategory = this.getEventCategory().find(v => v.label === 'relay')?.checked ?? false
    const isCheckedAutomationRuleCategory = this.getEventCategory().find(v => v.label === 'automation')?.checked ?? false

    if(isCheckedAutomationRuleCategory) {
      if(!isCheckedAudioCategory && this.isAudio(log)) return log.isAutomation
      if(isCheckedAudioCategory && this.isAudio(log)) return true
      if(!isCheckedBridgeCategory && this.isBridge(log)) return log.isAutomation
      if(isCheckedBridgeCategory && this.isBridge(log)) return true
      if(!isCheckedRelayCategory && this.isRelay(log)) return log.isAutomation
      if(isCheckedRelayCategory && this.isRelay(log)) return true
    } 
    if(!isCheckedAutomationRuleCategory) {
      if(!isCheckedAudioCategory && this.isAudio(log)) return false
      if(isCheckedAudioCategory && this.isAudio(log)) return !log.isAutomation
      if(!isCheckedBridgeCategory && this.isBridge(log)) return false
      if(isCheckedBridgeCategory && this.isBridge(log)) return !log.isAutomation
      if(!isCheckedRelayCategory && this.isRelay(log)) return false
      if(isCheckedRelayCategory && this.isRelay(log)) return !log.isAutomation
    }
  }
  // -------------------------------------------------------


  // -------------------------------------------------------
  // STEP 3: 스냅샷 연결하기 -----------------------------------
  public async setLogSnapshots(logs){
    const dealerId = await this.helper.me.get_my_dealer_id()
    const siteId = this.sitesService.selSite.site_id

    try {
      const tmpEventIds = [];
      let eventIds = '';
      logs.forEach(log=>{
        if(!log?.event_id) return log.isLoading = false
        if(log?.imageUrl) return log.isLoading = false
        if(log?.videoUrl) return log.isLoading = false
        if(
          !this.isVideoEvent(log) && 
          !this.isAutomationEvent(log) && 
          !this.isTimelapse(log) && 
          !this.isPlaybackEvent(log) && 
          !this.isDisarmedEvent(log)
        ) return log.isLoading = false
        tmpEventIds.push(log.event_id);
      })
      eventIds = tmpEventIds.join(',');
  
      const res = await this.eventsService.getEventsReportsInfoSnapshots(dealerId, siteId, eventIds, '3,8')?.toPromise()
      if(res?.has_download_permission || res?.has_share_download_link_permission) this.snapshotPermission$s.next(true)
      if(!res?.events) return

      for(const eventId in res.events) {
        const event = res.events[eventId]
        const log = logs.find(v => v.event_id === eventId)
        if(!log) {
          log.isLoading = false
          continue 
        }

        const jpgImg = event.find(v => v.video_type === 'jpg')
        const mp4Video = event.find(v => v.video_type === 'mp4')

        if(jpgImg) {
          log['isPrivacy'] = !!jpgImg.privacy_enabled
          log['is_expired'] = jpgImg.is_expired
          await this.getImageUrl(log, jpgImg.url)
        } else {
          log['isPrivacy'] = !!mp4Video?.privacy_enabled
          log['is_expired'] = mp4Video?.is_expired
          await this.getThumbnail(dealerId, siteId, eventId, log)
        }

        if(mp4Video?.url) {
          log['videoUrl'] = mp4Video?.url
          if(!this.isVideoEvent(log)) await this.getMP4Video(log, mp4Video.url)
          if(this.isVideoEvent(log) && this.isAutomationEvent(log)) await this.getMP4Video(log, mp4Video.url)
        } else {
          log.isLoading = false 
        }
        log.isLoading = false 
      }
    } catch(err) {
      logs.forEach(log => log.isLoading = false)
      this.snapshotPermission$s.next(false)
      console.debug('setLogSnapshots',err)
    }
  }

  private async getThumbnail(dealerId, siteId, eventId, log){
    // 이것을 먼저 포함하고 호출하면 최대 30초의 로딩이 걸리는 경우도 생김
    try {
      const thumbnailImgs = await this.eventsService.getEventsReportsInfoSnapshots(dealerId, siteId, eventId, '100')?.toPromise()
      if(!thumbnailImgs?.events) return
  
      const thumbnailEvent = thumbnailImgs.events[eventId]
      const thumbnailLog = log.event_id === eventId
      if(!thumbnailEvent) return
      if(!thumbnailLog) return
      
      const thumbnailImg = thumbnailEvent.find(v => v.is_thumbnail)
      if(thumbnailImg) await this.getImageUrl(log, thumbnailImg?.url)
    } catch(err) {
      console.debug('getThumbnail',err)
    }
  }

  private async getImageUrl(log, url){
    let urlCreator = window.URL;
    if(log.imageUrl) return log.isLoading = false 
    try {
      const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
      log['imageUrl'] = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res));
      log.isLoading = false 
    } catch(err) {
      log.isLoading = false 
    }
  }

  private async getMP4Video(log, url) {
    let urlCreator = window.URL;
    if(log.safetyVideoUrl) return log.isLoading = false 
    try {
      const res = await this.eventsService.getSnapshotFromUrl(url).toPromise()
      // console.log(res, this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res)))
      log['safetyVideoUrl'] = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res));
      log.isLoading = false 
    } catch(err) {
      log.isLoading = false 
    }
  }

  // -------------------------------------------------------
  // STEP 4: Padding으로 불러온 로그들 숨기기 --------------------
  private filteredUnusedLogs(logs): any[]{
    let selectedDate = this.selectedDate$s.value
    if(this.selectedDate$s.value === 'Today') selectedDate = this.today

    const startTime = moment(selectedDate, 'MMM / DD / YYYY').startOf('day').utc()
    const endTime =  moment(selectedDate, 'MMM / DD / YYYY').endOf('day').utc()
    const result = logs.filter(log => {
      const logEventTime = moment(log.eventCreated, 'MM/DD/YY hh:mm:ss a').utc()
      if(moment(logEventTime).isBefore(startTime)) return false
      if(moment(logEventTime).isAfter(endTime)) return false
      return true
    })
    return result
  }


  // ------------------------------------------------
  // Find category / filter
  private isAutomationActionAudio(log){
    const status_text = log.status_text.toLowerCase()
    const isAutomationRule = status_text.includes('rule')
    return isAutomationRule
  }
  private isAutomationCheckingByDescription(log){
    const description = log.description.toLowerCase()
    const isAutomationRule = description.includes('automation')
    return isAutomationRule
  }

  private isVideoEvent(log){
    const category = log.category.toLowerCase()
    return category === 'video event'
  }
  private isArmingEvent(log){
    const category = log.category.toLowerCase()
    return category === 'arming'
  }
  private isAudio(log){
    const category = log.category.toLowerCase()
    return category.includes('audio') ? true : false
  }
  private isBridge(log){
    const category = log.category.toLowerCase()
    return category.includes('bridge') ? true : false
  }
  private isPlaybackEvent(log){
    const category = log.category.toLowerCase()
    return category === 'playback event'
  }
  private isRelay(log){
    const category = log.category.toLowerCase()
    return category === 'relay'
  }
  private isTimelapse(log){
    const category = log.category.toLowerCase()
    return category === 'timelapse event' ? true : false
  }
  private isDisarmedEvent(log){
    const category = log.category.toLowerCase()
    return category === 'disarmed event' ? true : false
  }
  private isSensor(log){
    const category = log.category.toLowerCase()
    return category === 'sensor'
  }
  private isVideoAILog(log){
    const trigger = log.trigger.toLowerCase()
    return trigger.includes('videoai') ? true : false
  }
  private isVerification(log){
    const category = log.category.toLowerCase()
    return category === 'verification'
  }
  private isAutomationEvent(log){
    return log.isAutomation
  }
  private isVerificationEvent(log){
    return log.isHasVerification
  }
  private isOperatorViewed(log){
    return this.operatorMemberIds.includes(log.user_id)
  }
  // ------------------------------------------------

  // --------------------------------------------
  // SORT
  public sortLogs(){
    const sortOrder = this.sortOrder$s.value
    const isAsc = sortOrder === 'Ascending' ? true : false
    const filteredLogs = this.filteredLogs$s.value
    filteredLogs.sort((a, b) => this.compare(a.event_time, b.event_time, isAsc))
    this.filteredLogs$s.next(filteredLogs);
    console.log('sortLogs end')
  }

  private sortSelectedLogs(value){
    const selectedLogs = value
    const sortOrder = this.sortOrder$s.value
    const isAsc = sortOrder === 'Ascending' ? true : false
    selectedLogs.sort((a, b) => this.compare(a.event_time, b.event_time, isAsc));
    this.selectedLogs$s.next(selectedLogs);
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

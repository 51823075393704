import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '@app/services/common.service';
import { c_components } from 'src/3ui/2components';
import { Helper } from '../../../../../4services/2helper'
import ims from '../../imports'

@Component({
  selector: 'c_dtable_site_default_settings_for_new_site',
  templateUrl: './c_dtable_site_default_settings_for_new_site.component.pug',
  styleUrls: ['../../common.scss', '../site_default_settings.scss'],
})
export class c_dtable_site_default_settings_for_new_site_component {
  isLoading = false;

  tableHeader = [
    { name: 'SETTINGS', value: 'site_name', width: "20%", isCheckbox: false },
    { name: 'CHEKT DEFAULT VALUES', value: 'dealer_name', width: "15%", isCheckbox: false },
    { name: 'DEALER DEFAULT VALUES', value: 'video_events', width: "50%", isCheckbox: false },
  ]
  isEditMode = false;

  // selected settings
  isAllSelected = false;
  filteredSiteList = [];

  // validate
  isHasError = {
    zoneNumberRange: false,
    entryDelay: false,
    exitDelay: false,
    alarmInterval: false,
    swingerBypass: false,
    mp4VideoSettingsPre: false,
    mp4VideoSettingsPost: false,
  };

  // for new sites
  originSiteSettings = {
    zoneNumberRange: 101,
    entryDelay: 60,
    exitDelay: 120,
    alarmInterval: 20,
    swingerBypass: 0,
    mp4VideoSettings: { pre: 7, post: 25 },
    timeSync: true,
    autoSetStream: true,
  }
  newSiteSettings = {
    zoneNumberRange: 101,
    entryDelay: 60,
    exitDelay: 120,
    alarmInterval: 20,
    swingerBypass: 0,
    mp4VideoSettings: { pre: 7, post: 25 },
    timeSync: true,
    autoSetStream: true,
  }

  constructor(
    public commonService: CommonService, 
    private helper: Helper, 
    private c_components: c_components
  ) {}

  private dealerDefaultMpSettings$w: Subscription;
  watch() {
    this.dealerDefaultMpSettings$w = this.helper.mp_settings.dealerDefaultMpSettings$w().subscribe((v) => this.onChangeSiteDefaultSettings(v));
  }
  unwatch() {
    this.dealerDefaultMpSettings$w?.unsubscribe();
  }

  async ngOnInit() {
    this.isLoading = true;
    
    this.initData()
    this.watch();
    await this.loadData();
    this.isLoading = false;
  }
  ngOnDestroy(): void {
    this.unwatch();
  }

  initData(){
    this.newSiteSettings = {
      zoneNumberRange: 101,
      entryDelay: 60,
      exitDelay: 120,
      alarmInterval: 20,
      swingerBypass: 0,
      mp4VideoSettings: { pre: 7, post: 25 },
      timeSync: true,
      autoSetStream: true,
    }
    this.originSiteSettings = ims._.cloneDeep(this.newSiteSettings)
    this.isHasError = {
      zoneNumberRange: false,
      entryDelay: false,
      exitDelay: false,
      alarmInterval: false,
      swingerBypass: false,
      mp4VideoSettingsPre: false,
      mp4VideoSettingsPost: false,
    };
  }

  async loadData() {
    await this.helper.mp_settings.load_dealer_default_mp_settings();
  }
  onChangeSiteDefaultSettings(value) {
    if(!value || !value[0]) return;
    const data = value[0]
    const { site_default_setting } = data
    const settingsData = JSON.parse(site_default_setting)
    const { 
      init_zone_number,
      is_time_sync_enabled,
      auto_resolution_enabled,
      recording_interval_seconds,
      pre_event_mp4_second,
      post_event_mp4_second,
      entry_delay,
      exit_delay,
      swinger_bypass_threshold,
    } = settingsData

    this.originSiteSettings = {
      zoneNumberRange: init_zone_number,
      entryDelay: entry_delay,
      exitDelay: exit_delay,
      alarmInterval: recording_interval_seconds,
      swingerBypass: swinger_bypass_threshold,
      mp4VideoSettings: { pre: pre_event_mp4_second, post: post_event_mp4_second },
      timeSync: is_time_sync_enabled ? true : false,
      autoSetStream: auto_resolution_enabled ? true : false,
    }
    this.newSiteSettings = {
      zoneNumberRange: init_zone_number,
      entryDelay: entry_delay,
      exitDelay: exit_delay,
      alarmInterval: recording_interval_seconds,
      swingerBypass: swinger_bypass_threshold,
      mp4VideoSettings: { pre: pre_event_mp4_second, post: post_event_mp4_second },
      timeSync: is_time_sync_enabled ? true : false,
      autoSetStream: auto_resolution_enabled ? true : false,
    }
  }

  // -----------------------------------------
  // TOOLS
  toggleAllCheckbox() {
    this.isAllSelected = !this.isAllSelected
  }

  toggleCheckbox() {
    // 무언가 값을 변경하기
  }

  // -----------------------------------------
  // VALIDATE
  validateZoneNumberRange(){
    this.isHasError.zoneNumberRange = false;
    if(this.newSiteSettings.zoneNumberRange < 1 || this.newSiteSettings.zoneNumberRange > 600) {
      this.isHasError.zoneNumberRange = true;
    }
  }
  validateEntryDelay(){
    this.isHasError.entryDelay = false;
    if(this.newSiteSettings.entryDelay < 10 || this.newSiteSettings.entryDelay > 900) {
      this.isHasError.entryDelay = true;
    }
  }
  validateExitDelay(){
    this.isHasError.exitDelay = false;
    if(this.newSiteSettings.exitDelay < 10 || this.newSiteSettings.exitDelay > 900) {
      this.isHasError.exitDelay = true;
    }
  }
  validateAlarmInterval(){
    this.isHasError.alarmInterval = false;
    if(this.newSiteSettings.alarmInterval < 10 || this.newSiteSettings.alarmInterval > 3600) {
      this.isHasError.alarmInterval = true;
    }
  }
  validateMp4VideoSettingsPre(){
    this.isHasError.mp4VideoSettingsPre = false;
    if(this.newSiteSettings.mp4VideoSettings.pre < 5 || this.newSiteSettings.mp4VideoSettings.pre > 10) {
      this.isHasError.mp4VideoSettingsPre = true;
    }
  }
  validateMp4VideoSettingsPost(){
    this.isHasError.mp4VideoSettingsPost = false;
    if(this.newSiteSettings.mp4VideoSettings.post < 10 || this.newSiteSettings.mp4VideoSettings.post > 60) {
      this.isHasError.mp4VideoSettingsPost = true;
    }
  }
  isDisabledSave(){
    const value = Object.values(this.isHasError)
    return value.some(v => v === true)
  }

  // -----------------------------------------
  // STYLES
  setTooltipPosition(target) {
    let tooltipBox = document.querySelector('#'+target +'-box');
    let tooltip = document.getElementById(target);
    let rect = tooltipBox.getBoundingClientRect();
    let tooltipRect = tooltip.getBoundingClientRect();
    let posX = rect.left + (rect.right - rect.left) - tooltipRect.width/2;
    const posY = rect.top - tooltipRect.height - 55;
    if(!this.commonService.isHide) posX = posX - 200;
    tooltip.style.left = posX + 'px';
    tooltip.style.top = posY + 'px';
  }

  // ------------------------------------------------------------------------
  // ACTION 
  onClickCancel(){
    this.newSiteSettings = ims._.cloneDeep(this.originSiteSettings)
    this.isEditMode = false;
  }

  onClickSave(){
    if(this.isDisabledSave()) return;
    this.c_components.dialog.open("warning", {
      header: `Update the settings for the new site`,
      contents: `The changes will be applied starting from newly created sites.`,
      submit_btn: "Update",
      submit_class: ["button-primary"],
      icon: 'warning',
      isConfirm: false,
      color: 'orange',
      submit_func: () => {
        this.saveSettings()
      },
      cancel_func: () => this.onClickCancel(),
    });
  }
  
  async saveSettings(){
    try {
      this.isLoading = true
      // api call
      const data = {
        site_default_setting: {
          init_zone_number: this.newSiteSettings.zoneNumberRange,
          entry_delay: this.newSiteSettings.entryDelay,
          exit_delay: this.newSiteSettings.exitDelay,
          recording_interval_seconds: this.newSiteSettings.alarmInterval,
          swinger_bypass_threshold: this.newSiteSettings.swingerBypass,
          pre_event_mp4_second: this.newSiteSettings.mp4VideoSettings.pre,
          post_event_mp4_second: this.newSiteSettings.mp4VideoSettings.post,
          is_time_sync_enabled: this.newSiteSettings.timeSync ? 1 : 0,
          auto_resolution_enabled: this.newSiteSettings.autoSetStream ? 1 : 0,
        }
      }
      await this.helper.mp_settings.update_dealer_default_site_settings(data)
      this.openConfirmDialog('Success', `The settings have been successfully applied for New Sites`, 'done', 'green')
      this.initData()
      await this.loadData()
      this.isEditMode = false;
      this.isLoading = false;
    } catch(err) {
      console.debug(err)
      this.isLoading = false;
    }
  }

  // ------------------------------------------------------------------------
  openConfirmDialog(header = "", msg = "", icon = "done", color = "green") {
    this.c_components.dialog.open("warning", {
      header: header,
      contents: `
        <p>${msg}</b></p>
      `,
      submit_btn: "OK",
      submit_class: ["button-primary"],
      icon: icon,
      isConfirm: true,
      color: color,
      submit_func: () => {},
    });
  }
}
import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Helper } from "../../../../4services/2helper";
import { CommonService } from '../../../../app/services/common.service'

import ims from '../../imports'
import * as _ from 'lodash';

@Component({
  templateUrl: './c_dialog_mp_settings_bulk_update_component.pug',
  styleUrls: ['../common.scss', './c_dialog_mp_settings_bulk_update_component.scss'],
})
export class c_dialog_mp_settings_bulk_update_component {
  dealerMpSettings = _.cloneDeep(this.data.dealerMpSettings)

  doubleCheckText:string = ''

  // checkbox 용
  visibilityForMpSettings = {
    allowed_stream_quality: false,
    is_intercom_visible: false,
    is_listen_visible: false,
    is_ptz_visible: false,
    shared_site_mp_setting_editable: false,
    is_camera_relay_visible: false,
  }

  // toggle 용
  mpSettings = {
    allowed_stream_quality: false,
    is_intercom_visible: false,
    is_listen_visible: false,
    is_ptz_visible: false,
    shared_site_mp_setting_editable: false,
    is_camera_relay_visible: false,
  }

  constructor(
    public dialogRef: MatDialogRef<c_dialog_mp_settings_bulk_update_component>,
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private helper: Helper,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  async onClickNext(){
    try {
      const settings = {}
      for (const key in this.visibilityForMpSettings) {
        if (this.visibilityForMpSettings.hasOwnProperty(key)) { // 객체의 고유 속성만 반복
          if (this.visibilityForMpSettings[key]) settings[key] = this.mpSettings?.[key]
        }
      }
      ims.tool.sleep(100)
      this.close_dialog(settings)
    } catch(err){
      console.debug(err)
    }
  }

  // ------------------------------------------------------------------------
  isDisabledNextButton(){
    if(!this.visibilityForMpSettings.shared_site_mp_setting_editable && 
      !this.visibilityForMpSettings.is_ptz_visible && 
      !this.visibilityForMpSettings.is_listen_visible &&
      !this.visibilityForMpSettings.is_intercom_visible &&
      !this.visibilityForMpSettings.allowed_stream_quality &&
      !this.visibilityForMpSettings?.is_camera_relay_visible) return true;

    return false
  }

  public close_dialog(result: any = null): void {
    this.dialogRef.close(result);
  }
}

import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@app/services/common.service';
import { c_components } from 'src/3ui/2components';
import { Helper } from '../../../../../4services/2helper'
import ims from '../../imports'

@Component({
  selector: 'c_dtable_site_default_settings_for_existed_site',
  templateUrl: './c_dtable_site_default_settings_for_existed_site.component.pug',
  styleUrls: ['../../common.scss', '../site_default_settings.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class c_dtable_site_default_settings_for_existed_site_component {
  isLoading = false;

  tableHeader = [
    { name: '', value: null, width: "5%", isCheckbox: true },
    { name: 'SETTINGS', value: 'site_name', width: "20%", isCheckbox: false },
    { name: 'DEFAULT VALUE', value: 'dealer_name', width: "15%", isCheckbox: false },
    { name: 'RECOMMEND VALUE', value: 'number_of_cameras', width: "15%", isCheckbox: false },
    { name: 'SET VALUE', value: 'video_events', width: "50%", isCheckbox: false },
  ]

  // validate
  isHasError = {
    entryDelay: false,
    exitDelay: false,
    alarmInterval: false,
    swingerBypass: false,
    mp4VideoSettingsPre: false,
    mp4VideoSettingsPost: false,
  };

  // selected settings
  isAllSelected = false;
  selectedSettings = {
    entryDelay: false,
    exitDelay: false,
    alarmInterval: false,
    swingerBypass: false,
    mp4VideoSettings: false,
    timeSync: false,
    autoSetStream: false,
  };

  // for existed sites
  originSiteSettings = {
    entryDelay: 60,
    exitDelay: 60,
    alarmInterval: 3600,
    swingerBypass: 0,
    mp4VideoSettings: { pre: 7, post: 10 },
    timeSync: false,
    autoSetStream: true,
  }
  existedSiteSettings = {
    entryDelay: 60,
    exitDelay: 60,
    alarmInterval: 3600,
    swingerBypass: 0,
    mp4VideoSettings: { pre: 7, post: 10 },
    timeSync: false,
    autoSetStream: true,
  }

  constructor(
    public commonService: CommonService, 
    private helper: Helper, 
    private c_components: c_components,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initData()
  }

  initData(){
    this.selectedSettings = {
      entryDelay: false,
      exitDelay: false,
      alarmInterval: false,
      swingerBypass: false,
      mp4VideoSettings: false,
      timeSync: false,
      autoSetStream: false,
    };
    this.existedSiteSettings = {
      entryDelay: 60,
      exitDelay: 60,
      alarmInterval: 3600,
      swingerBypass: 0,
      mp4VideoSettings: { pre: 7, post: 10 },
      timeSync: false,
      autoSetStream: true,
    }
    this.originSiteSettings = ims._.cloneDeep(this.existedSiteSettings)
    this.isHasError = {
      entryDelay: false,
      exitDelay: false,
      alarmInterval: false,
      swingerBypass: false,
      mp4VideoSettingsPre: false,
      mp4VideoSettingsPost: false,
    };
  }

  // -----------------------------------------
  // TOOLS
  toggleAllCheckbox() {
    this.isAllSelected = !this.isAllSelected
    this.changeAllSelectedList()
  }

  toggleCheckbox() {
    const values = Object.values(this.selectedSettings)
    values.every(value => value) ? this.isAllSelected = true : this.isAllSelected = false
  }

  changeAllSelectedList(){
    if(this.isAllSelected) {
      this.selectedSettings = {
        entryDelay: true,
        exitDelay: true,
        alarmInterval: true,
        swingerBypass: true,
        mp4VideoSettings: true,
        timeSync: true,
        autoSetStream: true,
      }
    } else {
      this.selectedSettings = {
        entryDelay: false,
        exitDelay: false,
        alarmInterval: false,
        swingerBypass: false,
        mp4VideoSettings: false,
        timeSync: false,
        autoSetStream: false,
      }
    }
  }

  // -----------------------------------------
  // VALIDATE
  validateEntryDelay(){
    this.isHasError.entryDelay = false;
    if(this.existedSiteSettings.entryDelay < 10 || this.existedSiteSettings.entryDelay > 900) {
      this.isHasError.entryDelay = true;
    }
  }
  validateExitDelay(){
    this.isHasError.exitDelay = false;
    if(this.existedSiteSettings.exitDelay < 10 || this.existedSiteSettings.exitDelay > 900) {
      this.isHasError.exitDelay = true;
    }
  }
  validateAlarmInterval(){
    this.isHasError.alarmInterval = false;
    if(this.existedSiteSettings.alarmInterval < 10 || this.existedSiteSettings.alarmInterval > 3600) {
      this.isHasError.alarmInterval = true;
    }
  }
  validateMp4VideoSettingsPre(){
    this.isHasError.mp4VideoSettingsPre = false;
    if(this.existedSiteSettings.mp4VideoSettings.pre < 5 || this.existedSiteSettings.mp4VideoSettings.pre > 10) {
      this.isHasError.mp4VideoSettingsPre = true;
    }
  }
  validateMp4VideoSettingsPost(){
    this.isHasError.mp4VideoSettingsPost = false;
    if(this.existedSiteSettings.mp4VideoSettings.post < 10 || this.existedSiteSettings.mp4VideoSettings.post > 60) {
      this.isHasError.mp4VideoSettingsPost = true;
    }
  }

  // -----------------------------------------
  // STYLES
  setTooltipPosition(target) {
    let tooltipBox = document.querySelector('#'+target +'-box');
    let tooltip = document.getElementById(target);
    let rect = tooltipBox.getBoundingClientRect();
    let tooltipRect = tooltip.getBoundingClientRect();
    let posX = rect.left + (rect.right - rect.left) - tooltipRect.width/2;
    const posY = rect.top - tooltipRect.height - 55;
    if(!this.commonService.isHide) posX = posX - 200;
    tooltip.style.left = posX + 'px';
    tooltip.style.top = posY + 'px';
  }

  isDisabledButton(){
    const value = Object.values(this.isHasError)
    const isHasNoError = value.every(v => v === false)
    console.log('isHasNoError', isHasNoError)
    for(const key in this.selectedSettings){
      if(this.selectedSettings[key] && isHasNoError) return false
    }
    return true
  }

  isShowCancelButton(){
    const value = Object.values(this.selectedSettings)
    const isHasSelected = value.some(v => v === true)
    return isHasSelected
  }

  // -----------------------------------------
  // ACTIONS
  onClickCancel(){
    this.existedSiteSettings = ims._.cloneDeep(this.originSiteSettings)
    for(const key in this.selectedSettings){
      this.selectedSettings[key] = false
    }
  }

  async onClickBulkUpdate(){
    const data = {}
    for(const key in this.selectedSettings){
      if(this.selectedSettings[key]){
        data[key] = this.existedSiteSettings[key]
      }
    }
    const result = await this.c_components.dialog.open_and_wait_result('site_default_settings', {
      selectedSettings: data,
    })
    // result에 따라 refresh 로직을 적용하기
    if(result) {
      this.initData()
      this.cdr.detectChanges()
    }
  }
}
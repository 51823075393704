import { Injectable } from "@angular/core";
import { Imports } from "../imports";
import { Permission } from '../permission'
import { dealer_plan } from '../dealer_plan'
import { Observable } from "rxjs";
import tool from "../../../0lib/0tool";

@Injectable()
export class incident_reports {
  constructor(
    private ims: Imports, 
    private permission: Permission,
    private dealer_plan: dealer_plan
  ) {}
  
  public incident_reports$w(): Observable<any> {
    return this.ims.incidentReportsService.incidentReports$w;
  }
  public incident_report$w(): Observable<any> {
    return this.ims.incidentReportsService.incidentReport$w;
  }
  public shared_log$w(): Observable<any> {
    return this.ims.incidentReportsService.sharedLog$w;
  }

  public async get_incident_reports(site_id): Promise<Array<any>> | undefined {
    if(!this.ims.incidentReportsService.incidentReports$s.getValue()?.length) await this.load_incident_reports(site_id);
    return this.ims.incidentReportsService.incidentReports$s.getValue();
  }

  public async get_incident_report(site_id, incident_report_id): Promise<any> | undefined {
    if(!this.ims.incidentReportsService.incidentReport$s.getValue()?.length) await this.load_incident_report(site_id, incident_report_id);
    return this.ims.incidentReportsService.incidentReport$s.getValue();
  }

  public async get_shared_log(site_id, incident_report_id): Promise<Array<any>> | undefined {
    await this.load_shared_log(site_id, incident_report_id);
    return this.ims.incidentReportsService.sharedLog$s.getValue();
  }

  // ----------------------------------------
  public async load_incident_report(site_id: number | string, incident_report_id: number | string): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadIncidentReportsPermission = this.permission.has("dealer_site_incident_report_read")
      if(!hasReadIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.incidentReportsService.getSiteIncidentReport(dealer_id, site_id, incident_report_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Incident Report" });
      return false;
    }
  }

  public async load_incident_reports(site_id: number | string): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadIncidentReportsPermission = this.permission.has("dealer_site_incident_report_read")
      if(!hasReadIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.incidentReportsService.getSiteIncidentReports(dealer_id, site_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Incident Reports" });
      return false;
    }
  }

  public async load_shared_log(site_id: number | string, incident_report_id: number | string): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasReadIncidentReportsPermission = this.permission.has("dealer_site_incident_report_read")
      if(!hasReadIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.incidentReportsService.getSiteIncidentReportSharedLog(dealer_id, site_id, incident_report_id).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: "Get Shared Logs - Incident Report" });
      return false;
    }
  }

  // ----------------------------------------
  public async create_incident_report(site_id, data: any): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasCreateIncidentReportsPermission = this.permission.has("dealer_site_incident_report_create")
      if(!hasCreateIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.incidentReportsService.createSiteIncidentReport(dealer_id, site_id, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Incident Report` });
      return false;
    }
  }

  public async update_incident_report(site_id, incidentReportId, data: any): Promise<any> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      // 이건 update permission이 없고, create permission으로 적용됨.
      const hasUpdateIncidentReportsPermission = this.permission.has("dealer_site_incident_report_create")
      if(!hasUpdateIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res = await this.ims.incidentReportsService.updateSiteIncidentReport(dealer_id, site_id, incidentReportId, data).toPromise();
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Incident Report` });
      return false;
    }
  }

  public async delete_incident_report(site_id, incidentReportId): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasDeleteIncidentReportsPermission = this.permission.has("dealer_site_incident_report_delete")
      if(!hasDeleteIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.incidentReportsService.deleteSiteIncidentReport(dealer_id, site_id, incidentReportId).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Delete Incident Report` });
      return false;
    }
  }

  public async upload_incident_report_pdf(site_id, data: string | Blob): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasCreateIncidentReportsPermission = this.permission.has("dealer_site_incident_report_create")
      if(!hasCreateIncidentReportsPermission) return

      this.ims.incidentReportsService.uploadSiteIncidentReportPDF(site_id, data);
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Incident Report` });
      return false;
    }
  }

  public async download_incident_report_pdf(site_id, incident_report_id: any): Promise<Blob> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasCreateIncidentReportsPermission = this.permission.has("dealer_site_incident_report_create")
      if(!hasCreateIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      const res:Blob = await this.ims.incidentReportsService.downloadSiteIncidentReportPDF(dealer_id, site_id, incident_report_id);
      return res;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Download Incident Report PDF file` });
      return null;
    }
  }

  public async shared_incident_report(site_id, incident_report_id, data: any): Promise<boolean> {
    try {
      await this.permission.load_my_permissions_only_if_not_loaded()
      const hasSharedIncidentReportsPermission = this.permission.has("dealer_site_incident_report_create")
      if(!hasSharedIncidentReportsPermission) return

      const dealer_id = await this.get_my_dealer_id();
      if (!dealer_id) throw new Error("no dealer_id");

      await this.ims.incidentReportsService.shareSiteIncidentReport(dealer_id, site_id, incident_report_id, data).toPromise();
      return true;
    } catch (err) {
      tool.dispatch_event("api-failure", { err, title: `Create Shared Report` });
      return false;
    }
  }

  // ----------------------------------------
  private async get_my_dealer_id(): Promise<any> {
    await this.load_my_dealer_only_if_not_loaded();
    return this.ims.usersService.me?.dealer_id;
  }

  private load_my_dealer_only_if_not_loaded(): Promise<any> | undefined {
    if (this.my_dealer_already_loaded()) return;
    return this.ims.usersService.getMe().toPromise();
  }

  private my_dealer_already_loaded(): boolean {
    return !!this.ims.usersService.me.dealer_id;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { c_dtable_site_default_settings_for_existed_site_component } from './c_dtable_site_default_settings_for_existed_site.component';
import { SharedModule } from '../../../../../app/directives/shared.module'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ],
  declarations: [c_dtable_site_default_settings_for_existed_site_component],
  exports: [c_dtable_site_default_settings_for_existed_site_component],
})
export class c_dtable_site_default_settings_for_existed_site_module { }

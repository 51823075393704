import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '@app/services/common.service';
import { EventViewerService } from '@app/services/event-viewer.service';
import { DomSanitizer } from '@angular/platform-browser';

import { Helper } from 'src/4services/2helper';

@Component({
  selector: 'log_box_disarmed_event',
  templateUrl: './disarmed-event.component.pug',
  styleUrls: ['../../../common.scss','../../c_card_event_viewer_time_line.component.scss'],
})
export class log_box_disarmed_event_component {
  @Input() log;
  @Input() timezone;

  snapshotPermission = false;
  isBrokenImage = false;
  isLoadingVideo = false;

  constructor(
    public commonService: CommonService,
    private eventViewerService: EventViewerService,
    private sanitizer: DomSanitizer,
    private helper: Helper,
  ) {}

  private snapshotPermission$w: Subscription;
  watch() {
    this.snapshotPermission$w = this.eventViewerService.snapshotPermission$w.subscribe((v) => this.snapshotPermission = v);
  }
  unwatch() {
    this.snapshotPermission$w?.unsubscribe();
  }

  async ngOnInit(){
    this.watch()
    if(this.log.imageUrl) this.safeBlobUrl()  
  }

  ngOnDestroy(): void {
    this.unwatch()
  }

  // --------------------------------
  // IMAGE / VIDEO
  safeBlobUrl() {
    const url = this.log.imageUrl?.changingThisBreaksApplicationSecurity
    if(!url) return
    this.log.imageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  computedBackground(){
    if(this.log.isLoading) return 'loading-background'
    if(!this.snapshotPermission) return 'no permission'

    if(this.canIShowImage() === 'need to upgrade plan') return 'no-permission'
    if(this.canIShowImage() === 'expired') return 'no-permission'
    if(this.canIShowImage() === 'privacy mode') return 'no-permission'
    if(this.canIShowImage() === 'broken image') return 'no-permission'
    if(this.canIShowImage() === 'image') return 'full-height'
    if(this.canIShowImage() === 'default') return 'disarmed-event'
  }

  canIShowImage(){
    const highestPlan = this.helper.dealer_plan.isPremiumPlan() || this.helper.dealer_plan.isResellerPlan()
    if(!this.snapshotPermission) return 'no permission'
    if(highestPlan && this.log.is_expired) return 'expired'
    if(!highestPlan && this.log.is_expired) return 'need to upgrade plan'
    if(this.log.isPrivacy) return 'privacy mode'
    if(this.isBrokenImage) return 'broken image'
    if(this.log.imageUrl) return 'image'
    return 'default'
  } 
  
  // -------------------------------------------------------
  // COMMON
  parseWarningLog() {
    let isWarning = false;
    if (this.log.is_send_xml_signal === 2) {
      isWarning = true;
    }
    if (this.log.status) {
      if (this.log.status > 300) {
        isWarning = true;
      }
    } else {
      isWarning = true;
    }
    return isWarning;
  }

  isVideoAI(){
    const trigger = this.log.trigger.toLowerCase()
    return trigger.includes('videoai') ? true : false
  }
  parseVideoAIResult(meta){
    if(!meta || typeof meta != 'string') return 'Failed'
    const metaData = JSON.parse(meta)
    const body = metaData?.req?.body
    const entityIds = body?.summary_entity_ids
    if(!entityIds) return 'Failed'

    let cloudResult = ''
    const data = JSON.parse(entityIds)
    const result = Object.values(data)

    let person = ''
    let vehicle = ''
    const isAllPerson = result.every((entity: any[]) => entity?.includes('person'))
    const isAllVehicle = result.every((entity: any[]) => entity?.includes('vehicle'))

    if(data.static?.length) {
      if(data.static.includes('person')) person = 'Stationary Person'
      if(data.static.includes('vehicle')) vehicle = 'Stationary Vehicle'
    }
    if(data.motion?.length) {
      if(data.motion.includes('person')) person = 'Moving Person'
      if(data.motion.includes('vehicle')) vehicle = 'Moving Vehicle'
    }

    if(isAllPerson) person = 'Person'
    if(isAllVehicle) vehicle = 'Vehicle'

    if(person && vehicle) {
      cloudResult = `${person}, ${vehicle}`
    } else if(person || vehicle) {
      cloudResult = `${person || vehicle}`
    } else if(!person && !vehicle) {
      cloudResult = 'Nothing Detected'
    }
    return cloudResult
  }
}
